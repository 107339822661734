import { useLocalization } from '@goatlab/marketplace-i18n'
import { ScrollArea } from '@goatlab/react-ui'
import {
  UseZodFormHookReturnType,
  useParseInputProps,
} from '@goatlab/react-zod-form'
import { useRouter } from 'next/router'
import React, { FC, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { CheckBox } from '@components/ui/forms/fields/Checkbox'
import { useSearch } from '@contexts/search/search.context'
import { RouterOutputs, backendHook } from '@src/api/trpc'
import { useTypedTranslation } from '@src/utils/useTypedTranslation'

type Categories = RouterOutputs['categories']['grouped']

interface CategoryFilterProps {
  name: string
  defaultValue?: string[] | null
  triggerRouteChange?: boolean
  formHook?: UseZodFormHookReturnType<any>
  onChange?: (value: string[]) => void
}

export const CategoryFilter: FC<CategoryFilterProps> = ({
  name: nameProp,
  formHook: hook,
  defaultValue,
  onChange,
  triggerRouteChange,
}) => {
  const { t } = useTypedTranslation('common')
  const { setCategories: setStoreCategories } = useSearch()
  const router = useRouter()
  const { pathname, query } = router
  const [categories, setCategories] = useState<Categories>([])
  const currentLanguage = useLocalization().currentLanguageBackendFormat()

  const { data, isLoading } = backendHook.categories.grouped.useQuery(
    {
      lang: currentLanguage,
    },
    {
      refetchOnWindowFocus: false,
    },
  )

  useEffect(() => {
    // Avoid modifying the categories if it is loading
    if (!data || isLoading) {
      return
    }

    const services = data?.find(
      c => c.name === 'Services' || c.name === 'Servicios',
    )

    const cat = services
      ? services.children?.sort((a, z) => a.name.localeCompare(z.name))
      : []

    if (cat) {
      setCategories(cat)
    }
  }, [data])

  const selectedCategories = query?.category
    ? (query.category as string).split(',')
    : []
  const [formState, setFormState] = useState<string[]>(selectedCategories)

  useEffect(() => {
    setFormState(selectedCategories)
  }, [query?.category])

  useEffect(() => {}, [formState])

  const { name, formHook } = useParseInputProps({
    name: nameProp,
    formHook: hook,
  })

  if (isLoading) return <p>Loading...</p>

  return (
    <div className="block border-b border-gray-300 pb-7 mb-7">
      <h3 className="text-heading text-sm md:text-base font-semibold mb-7">
        {t('text-category')}
      </h3>
      <div className="mt-2 flex flex-col space-y-4">
        <Controller
          control={formHook?.control}
          name={name}
          defaultValue={defaultValue || []}
          render={({ field: { onChange: hookChange } }) => {
            return (
              <ScrollArea className="h-[350px] w-[255px]">
                {categories?.map((category: any) => {
                  const src = category.image?.src
                    ? category.image.src.replace('.jpg', '-150x150.jpg')
                    : ''

                  return (
                    <div style={{ flexDirection: 'row' }} key={category.id}>
                      <div className="mb-3">
                        <CheckBox
                          key={category.id}
                          src={src}
                          label={category.name}
                          name={category.name.toLowerCase()}
                          formHook={formHook}
                          onChange={() => {
                            const categoryName = category.name.toLowerCase()
                            const currentFormState = formState.includes(
                              categoryName,
                            )
                              ? formState.filter(i => i !== categoryName)
                              : [...formState, categoryName]

                            setFormState(currentFormState)
                            setStoreCategories(currentFormState)

                            if (triggerRouteChange) {
                              const newQuery = { ...query }

                              if (currentFormState.length) {
                                newQuery.category = currentFormState.join(',')
                              } else {
                                delete newQuery.category
                              }

                              void router.push(
                                {
                                  pathname,
                                  query: newQuery,
                                },
                                undefined,
                                { scroll: false, shallow: true },
                              )
                            }

                            onChange?.(currentFormState)
                            hookChange(currentFormState)
                          }}
                        />
                      </div>
                    </div>
                  )
                })}
              </ScrollArea>
            )
          }}
        />
      </div>
    </div>
  )
}
