import {
  AccountCreateManyInputSchema,
  AccountUncheckedUpdateWithoutOwnedAccountsInputSchema,
} from '@prisma/generated/zod'
import { LANGType as BackendLANGType } from '@prisma/generated/zod/inputTypeSchemas/LANGSchema'

// // Schemas
export const AccountUpdateInputSchema =
  AccountUncheckedUpdateWithoutOwnedAccountsInputSchema
export const AccountCreateInputSchema = AccountCreateManyInputSchema

// // Types
export type LANGType = BackendLANGType
