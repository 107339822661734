import { Button, useToast } from '@goatlab/react-ui'
import {
  FormStepProps,
  Form,
  useZodFormHook,
  useZustandForm,
} from '@goatlab/react-zod-form'
import { FC } from 'react'
import { useAuth } from '@components/auth/store/auth.store'
import { LanguageSwitcher } from '@components/ui/LanguageSwitcher'
import { Select } from '@components/ui/forms/fields/Select'
import { useStepperStore } from '@components/ui/stepper/Stepper'
import { AccountUpdateInputSchema } from '@src/types/backend'
import { useTypedTranslation } from '@src/utils/useTypedTranslation'

export const CountryAndLanguage: FC<
  FormStepProps<typeof AccountUpdateInputSchema>
> = ({ onSuccess, onError }) => {
  const { t } = useTypedTranslation('common')
  const { toast } = useToast()
  const { user } = useAuth()
  const { nextStep } = useStepperStore()
  const { formValues, setFormValues } = useZustandForm()

  const formHook = useZodFormHook({schema: AccountUpdateInputSchema, defaultValues: {
    country: formValues['country'] || '',
    language: formValues['language'] || 'en_us',
  }})

  const countries = [
    { value: 'CL', label: 'Chile' },
    { value: 'US', label: 'United States' },
  ]

  return (
    <Form
      notifyError={({ title, description }) => {
        toast({
          title,
          description,
          variant: 'destructive',
        })
      }}
      formHook={formHook}
      onSuccess={data => {
        if (!user?.account?.email || !user.account?.id) {
          toast({
            title: 'There is no user to assign the profile to',
            variant: 'destructive',
          })
          return
        }

        onSuccess?.(data)
        setFormValues(data)
        nextStep()
      }}
      onError={onError}>
      <div className="space-y-3 mb-8 min-h-[60vh]">
        <div className="flex-col pb-12 justify-center">
          <div className="w-full">
            <LanguageSwitcher
              tabIndex={1}
              name={formHook.path.language}
              label={t('language')}
              inModal={true}
              formHook={formHook}
            />
          </div>
          <div className="w-full">
            <Select
              tabIndex={2}
              name={formHook.path.country}
              label={t('country')}
              inModal={true}
              placeholder="Select your country"
              options={countries}
              formHook={formHook}
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4 place-content-around mt-10">
          <div></div>
          <Button
            color="primary"
            data-cy="wizard-next"
            type="submit"
            className="ml-auto">
            {t('next')}
          </Button>
        </div>
      </div>
    </Form>
  )
}
