import { useToast } from '@goatlab/react-ui'
import { Button } from '@goatlab/react-ui'
import {
  FormStepProps,
  Form,
  useZustandForm,
  useZodFormHook,
} from '@goatlab/react-zod-form'
import { FC } from 'react'
import { useAuth } from '@components/auth/store/auth.store'
import { Radio } from '@components/ui/forms/fields/Radio'
import { useStepperStore } from '@components/ui/stepper/Stepper'
import { AccountUpdateInputSchema } from '@src/types/backend'
import { useTypedTranslation } from '@src/utils/useTypedTranslation'

export const RoleInGealium: FC<
  FormStepProps<typeof AccountUpdateInputSchema>
> = ({ onSuccess, onError }) => {
  const { t } = useTypedTranslation(['common', 'onboarding'])
  const { user } = useAuth()
  const { toast } = useToast()
  const { nextStep, previousStep } = useStepperStore()
  const { formValues, setFormValues } = useZustandForm()

  const formHook = useZodFormHook({schema: AccountUpdateInputSchema, defaultValues: {
    type: formValues['type'] || '',
    firstName: formValues['firstName'] || '',
  }})

  const gealiumRolesOptions = [
    {
      label: t('as-user'),
      value: 'USER',
      leftIcon: (
        <svg className="w-6 h-6 shrink-0 fill-current mr-4" viewBox="0 0 24 24">
          <path
            className="text-indigo-500"
            d="m12 10.856 9-5-8.514-4.73a1 1 0 0 0-.972 0L3 5.856l9 5Z"
          />
        </svg>
      ),
    },
    {
      label: t('as-professional', { ns: 'onboarding' }),
      value: 'STAFF',
      leftIcon: (
        <svg className="w-6 h-6 shrink-0 fill-current mr-4" viewBox="0 0 24 24">
          <path
            className="text-indigo-500"
            d="m12 10.856 9-5-8.514-4.73a1 1 0 0 0-.972 0L3 5.856l9 5Z"
          />
          <path
            className="text-indigo-300"
            d="m11 12.588-9-5V18a1 1 0 0 0 .514.874L11 23.588v-11Z"
          />
        </svg>
      ),
    },
    {
      label: t('as-company', { ns: 'onboarding' }),
      value: 'COMPANY',
      leftIcon: (
        <svg className="w-6 h-6 shrink-0 fill-current mr-4" viewBox="0 0 24 24">
          <path
            className="text-indigo-500"
            d="m12 10.856 9-5-8.514-4.73a1 1 0 0 0-.972 0L3 5.856l9 5Z"
          />
          <path
            className="text-indigo-300"
            d="m11 12.588-9-5V18a1 1 0 0 0 .514.874L11 23.588v-11Z"
          />
          <path
            className="text-indigo-200"
            d="M13 12.588v11l8.486-4.714A1 1 0 0 0 22 18V7.589l-9 4.999Z"
          />
        </svg>
      ),
    },
  ]

  return (
    <Form
      formHook={formHook}
      onSuccess={data => {
        console.log('next')
        if (!user?.account?.email || !user.account?.id) {
          toast({
            title: 'There is no user to assign the profile to',
            variant: 'destructive',
          })
          return
        }
        setFormValues(data)
        onSuccess?.(data)
        nextStep()
      }}
      onError={onError}>
      <div className="min-h-[67vh]">
        <div className="space-y-3 mb-8">
          <Radio
            name={formHook.path.type}
            label={t('name', { ns: 'onboarding' })}
            options={gealiumRolesOptions}
            formHook={formHook}
          />

          <div className="grid grid-cols-2 gap-4 place-content-around mt-10">
            <Button
              variant={'secondary'}
              data-cy="wizard-back"
              type="button"
              onClick={() => {
                previousStep()
                setFormValues(formHook.getValues())
              }}>
              {t('back')}
            </Button>
            <Button
              color="primary"
              data-cy="wizard-next"
              type="submit"
              className="ml-auto">
              {t('next')}
            </Button>
          </div>
        </div>
      </div>
    </Form>
  )
}
