import { FC } from 'react'
import { useAuth } from '../store/auth.store'
import { useStepperStore } from '@components/ui/stepper/Stepper'
import { useTypedTranslation } from '@src/utils/useTypedTranslation'

interface TopLogoutProps {
  setDialogOpen: (open: boolean) => void
}

export const TopLogout: FC<TopLogoutProps> = ({ setDialogOpen }) => {
  const { user, logOut } = useAuth()
  const { reset } = useStepperStore()
  const { t } = useTypedTranslation('common')

  return (
    <div className="flex items-center justify-between px-4 sm:px-6 lg:px-8">
      <div className="text-xs">
        {user?.email}. {t('not-your-account')}{' '}
        <button
          tabIndex={-1}
          className="font-medium text-indigo-500 hover:text-indigo-600"
          onClick={async () => {
            setDialogOpen(false)
            await logOut()
            reset()
          }}>
          {t('sign-out')}
        </button>
      </div>
    </div>
  )
}
