import { useParseInputProps } from '@goatlab/react-zod-form'
import cn from 'clsx'
import React, { FC } from 'react'
import { Controller } from 'react-hook-form'
import { Label } from '../Label'
import { BaseField } from '../types/baseField'

type InputProps = BaseField<string> & {
  inputClassName?: string
  shadow?: boolean
  variant?: 'normal' | 'solid' | 'outline'
}

export const Input: FC<InputProps> = ({
  className = 'flex-1 mb-2',
  label,
  name: nameProp,
  placeholder,
  inputClassName,
  formHook: hook,
  defaultValue,
  disabled,
  transform,
  onChange,
  description,
  tabIndex,
}) => {
  const { name, formHook, errorMessage } = useParseInputProps({
    name: nameProp,
    formHook: hook,
  })

  const rootClassName = cn(
    'form-input py-2 px-4 md:px-5 w-full appearance-none transition duration-150 ease-in-out text-black text-xs lg:text-sm font-body rounded-md placeholder-body min-h-11 transition duration-200 ease-in-out',
    inputClassName,
    {
      'bg-gray-200 text-slate-500 cursor-not-allowed': disabled,
    },
  )

  return (
    <div className={className}>
      {label && <Label text={label} />}
      <Controller
        control={formHook.control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange: hookChange, ...rest } }) => {
          return (
            <input
              id={name}
              tabIndex={tabIndex}
              type={'text'}
              placeholder={placeholder}
              disabled={disabled}
              className={rootClassName}
              autoComplete="nope"
              spellCheck="false"
              aria-invalid={errorMessage ? 'true' : 'false'}
              onChange={event => {
                if (transform) {
                  event.target.value = transform(event.target.value)
                }

                onChange?.(event.target.value)
                hookChange(event.target.value)
              }}
              {...rest}
            />
          )
        }}
      />
      <h4 className="block text-xs font-medium mb-1 mt-1 ml-2">
        {description}
      </h4>
      {errorMessage && (
        <div className="mt-2 text-xs text-red-600 pl-1">
          {String(errorMessage)}
        </div>
      )}
    </div>
  )
}
