import { useParseInputProps } from '@goatlab/react-zod-form'
import cn from 'clsx'
import Image from 'next/image'
import React, { FC } from 'react'
import { Controller } from 'react-hook-form'
import { BaseField } from '../types/baseField'

type CheckBoxProps = BaseField<boolean> & {
  label?: string
  src?: string
  inputClassName?: string
}

export const CheckBox: FC<CheckBoxProps> = ({
  className = 'flex-1',
  label,
  name: nameProp,
  defaultValue,
  disabled,
  formHook: hook,
  inputClassName,
  description,
  onChange,
  src,
}) => {
  const { name, formHook, errorMessage } = useParseInputProps({
    name: nameProp,
    formHook: hook,
  })

  const rootClassName = cn(
    'form-checkbox w-5 h-5 border border-gray-300 rounded cursor-pointer transition duration-500 ease-in-out focus:ring-offset-0 hover:border-heading focus:outline-none focus:ring-0 focus-visible:outline-none checked:focus:bg-heading',
    inputClassName,
    {
      'cursor-not-allowed': disabled,
    },
  )

  return (
    <div className={className}>
      <Controller
        control={formHook.control}
        name={name}
        disabled={disabled}
        defaultValue={defaultValue}
        render={({ field: { onChange: hookChange, value, ...rest } }) => (
          <label className="group flex items-center text-heading text-sm cursor-pointer">
            <input
              id={name}
              type="checkbox"
              className={rootClassName}
              checked={value}
              onChange={event => {
                onChange?.(event.target.checked)
                hookChange(event)
              }}
              value={value}
              {...rest}
            />
            {src ? (
              <div className="relative h-10 w-10 ms-4 -mt-0.5">
                <Image src={src} alt={`${label}`} height={50} width={50} />
              </div>
            ) : null}
            <span className="ms-4 -mt-0.5 text-xs">{label}</span>
          </label>
        )}
      />
      <h4 className="block text-xs font-medium mb-1 mt-1 ml-2">
        {description}
      </h4>
      {errorMessage && (
        <div className="mt-2 text-xs text-red-600 pl-1">
          {String(errorMessage)}
        </div>
      )}
    </div>
  )
}
