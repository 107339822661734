import {
  DialogContent,
  DialogHeader,
  DialogTitle,
  useToast,
} from '@goatlab/react-ui'
import { Button } from '@goatlab/react-ui'
import { useZustandForm } from '@goatlab/react-zod-form'
import { useRouter } from 'next/router'
import React, { FC } from 'react'
import { TopLogout } from './TopLogout'
import { CountryAndLanguage } from './steps/CountryAndLanguage'
import { PersonalInformation } from './steps/PersonalInformation'
import { RoleInGealium } from './steps/RoleInGealium'
import { useAuth } from '@components/auth/store/auth.store'
import { CategoryFilter } from '@components/search/sideBarSearch/filters/CategoryFilter'
import { Step } from '@components/ui/stepper/Step'
import { Stepper, useStepperStore } from '@components/ui/stepper/Stepper'
import { backendHook } from '@src/api/trpc'
import { useStateAwareRoutes } from '@src/hooks/useStateAwareRoutes'
import { useTypedTranslation } from '@src/utils/useTypedTranslation'

interface OnboardingWizardProps {
  setDialogOpen: (open: boolean) => void
}

export const OnboardingWizard: FC<OnboardingWizardProps> = ({
  setDialogOpen,
}) => {
  const { reset, previousStep } = useStepperStore()
  const { user, setAccount, refreshAccount } = useAuth()
  const { toast } = useToast()
  const { t } = useTypedTranslation(['common', 'onboarding'])
  const stateRouter = useStateAwareRoutes()
  const { formValues, setFormValues, reset: resetForm } = useZustandForm()
  const router = useRouter()
  const { asPath } = router

  const updateById = backendHook.account.updateById.useMutation({
    onError(error: any) {
      toast({
        title: error.message,
        variant: 'destructive',
      })
    },
    async onSuccess(result) {
      // Clean up the URL
      await router.push(asPath, undefined, {
        scroll: false,
        shallow: true,
      })

      setAccount(result)
      refreshAccount()

      if (formValues.type === 'STAFF' || formValues.type === 'COMPANY') {
        toast({
          title: 'Welcome to Gealium!',
          description: 'Let`s setup your account',
        })

        if (!router.route.includes('invitation')) {
          await router.push(stateRouter.dynamic.ACCOUNT_SETTINGS_AVAILABILITY())
        }

        setDialogOpen(false)
        reset()
        return
      }

      toast({
        title: 'Welcome to Gealium!',
        description: 'Start looking for a professional',
      })

      if (!router.route.includes('invitation')) {
        await router.push(stateRouter.static.SEARCH)
      }

      setDialogOpen(false)
      reset()
      resetForm()
    },
  })

  const submitOnboardingAnswers = async () => {
    if (!user?.account?.email || !user.account?.id) {
      toast({
        title: 'There is no user to assign the profile to',
        variant: 'destructive',
      })
      return
    }

    updateById.mutate({
      accountId: user.account.id,
      updatedAccount: formValues,
    })
  }

  return (
    <DialogContent
      tabIndex={-1}
      className={'max-w-screen-lg overflow-y-auto max-h-screen'}>
      <DialogHeader tabIndex={-1}>
        <DialogTitle>Edit profile</DialogTitle>
        <TopLogout setDialogOpen={setDialogOpen} />
      </DialogHeader>

      <Stepper sideImageUrl="/assets/images/onboarding-image.jpg" fullScreen>
        <Step
          name={t('Language')}
          title={t('select-language-and-country', { ns: 'onboarding' })}>
          <CountryAndLanguage />
        </Step>

        <Step
          name={t('your-information', { ns: 'onboarding' })}
          title={t('your-information', { ns: 'onboarding' })}>
          <PersonalInformation />
        </Step>

        <Step
          name={t('how-will-you-use-gealium', { ns: 'onboarding' })}
          title={t('how-will-you-use-gealium', { ns: 'onboarding' })}>
          <RoleInGealium />
        </Step>

        <Step
          name={t('which-services', { ns: 'onboarding' })}
          title={t('which-services', { ns: 'onboarding' })}>
          <div className="space-y-3 mb-8">
            <CategoryFilter
              name="categories"
              onChange={cat => {
                setFormValues({ categories: cat })
              }}
            />
          </div>
          <div className="grid grid-cols-2 gap-4 place-content-around mt-10">
            <Button
              variant={'secondary'}
              data-cy="wizard-back"
              onClick={() => {
                previousStep()
              }}>
              {t('back')}
            </Button>

            <Button
              className="ml-auto"
              type="button"
              color="primary"
              disabled={updateById.isPending}
              onClick={async () => {
                await submitOnboardingAnswers()
              }}
              data-cy="wizard-submit">
              {t('start')}
            </Button>
          </div>
        </Step>
      </Stepper>
    </DialogContent>
  )
}
