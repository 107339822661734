import { FC, ReactNode } from 'react'

interface DoubleColumnProps {
  children: ReactNode
}

export const DoubleColumn: FC<DoubleColumnProps> = ({ children }) => {
  return (
    <div className="space-x-0 md:space-x-6 flex-col flex md:flex-row w-full justify-between">
      {children}
    </div>
  )
}
