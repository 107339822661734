import { Button } from '@goatlab/react-ui'
import {
  FormStepProps,
  Form,
  useZustandForm,
  useZodFormHook,
} from '@goatlab/react-zod-form'
import React, { FC } from 'react'
import { DatePicker } from '@components/ui/forms/dates/DatePicker'
import { Input } from '@components/ui/forms/fields/Input'
import { PhoneNumber } from '@components/ui/forms/fields/PhoneNumber'
import { Select } from '@components/ui/forms/fields/Select'
import { DoubleColumn } from '@components/ui/layout/grid/DoubleColumn'
import { useStepperStore } from '@components/ui/stepper/Stepper'
import { AccountUpdateInputSchema } from '@src/types/backend'
import { castToString } from '@src/utils/castToString'
import { useTypedTranslation } from '@src/utils/useTypedTranslation'

export const PersonalInformation: FC<
  FormStepProps<typeof AccountUpdateInputSchema>
> = ({ onSuccess, onError }) => {
  const { t } = useTypedTranslation(['common', 'onboarding'])
  const { nextStep, previousStep } = useStepperStore()
  const { formValues, setFormValues } = useZustandForm()

  const formHook = useZodFormHook({schema: AccountUpdateInputSchema, defaultValues: {
    displayName: formValues['displayName'] || '',
    firstName: formValues['firstName'] || '',
    lastName: formValues['lastName'] || '',
    title: formValues['title'] || '',
    slug: formValues['slug'] || '',
    birthDate: formValues['birthDate'] || '',
    sex: formValues['sex'] || '',
    phoneNumber: formValues['phoneNumber'] || '',
  }})

  const { displayName, firstName, lastName, title, slug, country } =
    formHook.watch()

  const sexOptions = [
    { value: 'male', label: t('male', { ns: 'onboarding' }) },
    { value: 'female', label: t('female', { ns: 'onboarding' }) },
    { value: 'none-binary', label: t('none-binary', { ns: 'onboarding' }) },
    { value: 'hidden', label: t('hidden', { ns: 'onboarding' }) },
  ]

  return (
    <Form
      formHook={formHook}
      onSuccess={async data => {
        onSuccess?.(data)
        setFormValues(data)
        nextStep()
      }}
      onError={onError}>
      <div className="min-h-[70vh]">
        <div
          className={`grow flex flex-col md:translate-x-0 transition-transform duration-300 ease-in-out translate-x-0 scale-75 -mt-8 -mb-8`}>
          {/* Content */}
          <div className="relative px-4 sm:px-6 pb-8">
            {/* Header */}

            <header className="text-center sm:text-left mb-6">
              {/* Name */}
              <div className="inline-flex items-start mb-2">
                <h1 className="text-2xl text-slate-800 font-bold">
                  {castToString(displayName)}
                </h1>
                <svg
                  className="w-4 h-4 fill-current shrink-0 text-amber-500 ml-2"
                  viewBox="0 0 16 16">
                  <path d="M13 6a.75.75 0 0 1-.75-.75 1.5 1.5 0 0 0-1.5-1.5.75.75 0 1 1 0-1.5 1.5 1.5 0 0 0 1.5-1.5.75.75 0 1 1 1.5 0 1.5 1.5 0 0 0 1.5 1.5.75.75 0 1 1 0 1.5 1.5 1.5 0 0 0-1.5 1.5A.75.75 0 0 1 13 6ZM6 16a1 1 0 0 1-1-1 4 4 0 0 0-4-4 1 1 0 0 1 0-2 4 4 0 0 0 4-4 1 1 0 1 1 2 0 4 4 0 0 0 4 4 1 1 0 0 1 0 2 4 4 0 0 0-4 4 1 1 0 0 1-1 1Z" />
                </svg>
              </div>
              {/* Bio */}
              <div className="text-sm mb-3">
                <>
                  ( {firstName} {lastName} ) {title}
                </>
              </div>
              {/* Meta */}
              <div className="flex flex-wrap justify-center sm:justify-start space-x-4">
                <div className="flex items-center">
                  <svg
                    className="w-4 h-4 fill-current shrink-0 text-slate-400"
                    viewBox="0 0 16 16">
                    <path d="M8 8.992a2 2 0 1 1-.002-3.998A2 2 0 0 1 8 8.992Zm-.7 6.694c-.1-.1-4.2-3.696-4.2-3.796C1.7 10.69 1 8.892 1 6.994 1 3.097 4.1 0 8 0s7 3.097 7 6.994c0 1.898-.7 3.697-2.1 4.996-.1.1-4.1 3.696-4.2 3.796-.4.3-1 .3-1.4-.1Zm-2.7-4.995L8 13.688l3.4-2.997c1-1 1.6-2.198 1.6-3.597 0-2.798-2.2-4.996-5-4.996S3 4.196 3 6.994c0 1.399.6 2.698 1.6 3.697 0-.1 0-.1 0 0Z" />
                  </svg>
                  <span className="text-sm font-medium whitespace-nowrap text-slate-500 ml-2">
                    {country ? String(country) : ''}
                  </span>
                </div>
                <div className="flex items-center">
                  <svg
                    className="w-4 h-4 fill-current shrink-0 text-slate-400"
                    viewBox="0 0 16 16">
                    <path d="M11 0c1.3 0 2.6.5 3.5 1.5 1 .9 1.5 2.2 1.5 3.5 0 1.3-.5 2.6-1.4 3.5l-1.2 1.2c-.2.2-.5.3-.7.3-.2 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l1.1-1.2c.6-.5.9-1.3.9-2.1s-.3-1.6-.9-2.2C12 1.7 10 1.7 8.9 2.8L7.7 4c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l1.2-1.1C8.4.5 9.7 0 11 0ZM8.3 12c.4-.4 1-.5 1.4-.1.4.4.4 1 0 1.4l-1.2 1.2C7.6 15.5 6.3 16 5 16c-1.3 0-2.6-.5-3.5-1.5C.5 13.6 0 12.3 0 11c0-1.3.5-2.6 1.5-3.5l1.1-1.2c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L2.9 8.9c-.6.5-.9 1.3-.9 2.1s.3 1.6.9 2.2c1.1 1.1 3.1 1.1 4.2 0L8.3 12Zm1.1-6.8c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-4.2 4.2c-.2.2-.5.3-.7.3-.2 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l4.2-4.2Z" />
                  </svg>
                  <a
                    className="text-sm font-medium whitespace-nowrap text-indigo-500 hover:text-indigo-600 ml-2"
                    href="#0"
                    tabIndex={-1}>
                    <>
                      https://gealium.com/
                      {slug}
                    </>
                  </a>
                </div>
              </div>
            </header>
          </div>
        </div>
        {/* htmlForm */}

        <div>
          {/* Name and Last Name */}
          <DoubleColumn>
            <Input
              name={formHook.path.firstName}
              label={t('name', { ns: 'onboarding' })}
              formHook={formHook}
              defaultValue={firstName ? String(firstName) : null}
              tabIndex={1}
            />

            <Input
              name={formHook.path.lastName}
              label={t('last-name', { ns: 'onboarding' })}
              defaultValue={lastName ? String(lastName) : null}
              formHook={formHook}
              tabIndex={2}
            />
          </DoubleColumn>

          {/* Profession & Displayname */}
          <DoubleColumn>
            {/* Display Name */}
            <Input
              name={formHook.path.displayName}
              label={t('display-name', { ns: 'onboarding' })}
              formHook={formHook}
              tabIndex={3}
            />

            <Input
              name={formHook.path.title}
              label={t('title-or-description', { ns: 'onboarding' })}
              formHook={formHook}
              tabIndex={4}
            />
          </DoubleColumn>

          <DoubleColumn>
            <DatePicker
              label={t('birthDate', { ns: 'onboarding' })}
              name={formHook.path.birthDate}
              formHook={formHook}
              tabIndex={5}
            />

            <Select
              label={t('sex', { ns: 'onboarding' })}
              inModal={true}
              name={formHook.path.sex}
              formHook={formHook}
              placeholder="Select a gender"
              options={sexOptions}
              tabIndex={6}
            />
          </DoubleColumn>

          {/* Slug & Number */}
          <DoubleColumn>
            <Input
              label={t('url', { ns: 'onboarding' })}
              name={formHook.path.slug}
              tabIndex={7}
              formHook={formHook}
              transform={val => {
                return val
                  .replace(/[^a-zA-Z0-9\s]+/g, '')
                  .replace(/\s+/g, '-')
                  .toLowerCase()
              }}
            />

            <PhoneNumber
              name={formHook.path.phoneNumber}
              label={t('phone-number', { ns: 'onboarding' })}
              formHook={formHook}
              tabIndex={8}
            />
          </DoubleColumn>
        </div>

        <div className="grid grid-cols-2 gap-4 place-content-around mt-10">
          <Button
            variant={'secondary'}
            data-cy="wizard-back"
            type="button"
            onClick={() => {
              previousStep()
            }}>
            {t('back')}
          </Button>
          <Button
            color="primary"
            data-cy="wizard-next"
            type="submit"
            className="ml-auto">
            {t('next')}
          </Button>
        </div>
      </div>
    </Form>
  )
}
