import { useParseInputProps } from '@goatlab/react-zod-form'
import { FC } from 'react'
import Flatpickr from 'react-flatpickr'
import { Controller } from 'react-hook-form'
import { BaseField } from '../types/baseField'

type DatePickerProps = BaseField<string>

export const DatePicker: FC<DatePickerProps> = ({
  label,
  name: nameProp,
  defaultValue,
  formHook: hook,
  onChange,
  tabIndex,
}) => {
  const { name, formHook, errorMessage } = useParseInputProps({
    name: nameProp,
    formHook: hook,
  })

  return (
    <Controller
      control={formHook?.control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange: hookChange, value, ...rest } }) => (
        <div className="flex-1 mb-6">
          <label
            className="block text-gray-600 font-semibold text-sm leading-none mb-3"
            htmlFor={name}>
            {label}

            <span className="text-rose-500"> *</span>
          </label>
          <Flatpickr
            id={name}
            options={{
              dateFormat: 'd-m-Y',
              static: true,
            }}
            tabIndex={tabIndex}
            className="block w-full border border-gray-300 rounded-md px-3 py-2 text-gray-600 font-medium text-sm leading-none focus:outline-none focus:ring-2"
            value={value ? new Date(value) : ''}
            onChange={([date]: any) => {
              const dateObject = new Date(date)
              // The hook expects an ISO string
              hookChange(dateObject.toISOString())
              // The onChange prop expects a Date object
              onChange?.(dateObject.toISOString())
            }}
            {...rest}
          />

          {errorMessage && (
            <div className="mt-2 text-xs text-red-600 pl-1">
              {String(errorMessage)}
            </div>
          )}
        </div>
      )}
    />
  )
}
