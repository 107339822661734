import { animated, useSpring } from '@react-spring/web'
import { clsx } from 'clsx'
import Image from 'next/image'
import { FC, useState, ReactElement, Children, useEffect } from 'react'
import { z } from 'zod'
import { create } from 'zustand'
import { Step, StepProps } from './Step'

interface StepperProps {
  schema?: z.ZodTypeAny
  children: ReactElement<typeof Step> | ReactElement<typeof Step>[]
  sideImageUrl?: string
  decorationImageUrl?: string
  top?: (currentStep: number) => ReactElement | null
  fullScreen?: boolean
}

interface StepperState {
  currentStep: number
  isLoading?: boolean
  nextStep: () => void
  previousStep: () => void
  setCurrentStep: (step: number) => void
  setIsLoading: (state: boolean) => void
  reset: () => void
}

export const useStepperStore = create<StepperState>(set => ({
  currentStep: 1,
  isLoading: false,
  setCurrentStep(step) {
    set({ currentStep: step })
  },
  nextStep() {
    set(state => ({ currentStep: state.currentStep + 1 }))
  },
  previousStep() {
    set(state => ({
      currentStep: state.currentStep > 0 ? state.currentStep - 1 : 0,
    }))
  },
  setIsLoading(step) {
    set({ isLoading: step })
  },
  reset() {
    set({ currentStep: 1, isLoading: false })
  },
}))

export const Stepper: FC<StepperProps> = ({
  children,
  top,
  sideImageUrl,
  decorationImageUrl,
  fullScreen,
}) => {
  const { currentStep } = useStepperStore()
  const style = useSpring({
    from: { transform: 'translate3d(0,1px,0)', opacity: 0 },
    to: { transform: 'translate3d(0,0px,0)', opacity: 1 },
    config: { tension: 210, friction: 20 },
  })

  const [decorationImage, setDecorationImage] = useState<string>(
    decorationImageUrl ?? '/assets/images/auth-decoration.png',
  )
  const [image, setImage] = useState<string | undefined>(sideImageUrl)

  useEffect(() => {
    setImage(sideImageUrl)
  }, [sideImageUrl])

  return (
    <div className="relative" tabIndex={-1}>
      <div
        className={clsx('', {
          'min-w-[400px]': !fullScreen,
        })}>
        <animated.div style={style}>
          <div className="relative flex">
            {/* Content */}
            <div
              className={clsx('w-full overflow-y-auto', {
                'md:w-1/2': !!image,
              })}>
              <div className={clsx('flex flex-col after:flex-1', {})}>
                <div className="flex-1">
                  {top?.(currentStep)}
                  {/* Progress bar */}
                  {Children.toArray(children).length > 1 && (
                    <div className="">
                      <div className="max-w-md mx-auto w-[90%] mr-auto ml-auto">
                        <div className="relative">
                          <div
                            className="absolute left-0 top-3 -mt-px w-full h-0.5 bg-slate-200"
                            aria-hidden="true"></div>
                          <ul className="relative flex justify-between w-full">
                            {Children.map(children, (child, index) => {
                              if (Children.count(children) === 1) {
                                return null
                              }

                              return (
                                <li
                                  key={index}
                                  className="relative flex flex-col items-center h-12" // Added relative here
                                >
                                  <div
                                    className={clsx(
                                      'absolute top-0 flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold',
                                      {
                                        // Made this div absolute and positioned it at the top
                                        'bg-primary-500 text-white':
                                          currentStep - 1 === index,
                                        'bg-slate-100 text-slate-500':
                                          currentStep - 1 !== index,
                                      },
                                    )}>
                                    {index + 1}
                                  </div>
                                  <div
                                    className="w-20 text-center break-words text-slate-500  mt-8"
                                    style={{ fontSize: '10px' }}>
                                    {/* Added mt-8 here */}
                                    {child.props.name}
                                  </div>
                                </li>
                              )
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}

                  {Children.map(children, (child, index) => {
                    if (index === currentStep - 1) {
                      const props = child.props as unknown as StepProps

                      if (props.sideImageUrl && image !== props.sideImageUrl) {
                        setImage(props.sideImageUrl)
                      }

                      if (
                        props.decorationImageUrl &&
                        decorationImage !== props.decorationImageUrl
                      ) {
                        setDecorationImage(props.decorationImageUrl)
                      }

                      return (
                        <animated.div key={index} style={style}>
                          {child}
                        </animated.div>
                      )
                    }

                    return null
                  })}
                </div>
              </div>
            </div>

            {/* Image */}
            {image && (
              <div
                className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2 h-full"
                aria-hidden="true">
                <Image
                  className="object-cover object-center w-full h-full"
                  src={image}
                  width="760"
                  height="1024"
                  alt="Onboarding"
                  priority={true}
                />
                <Image
                  className="absolute top-1/4 left-0 -translate-x-1/2 ml-8 hidden lg:block"
                  src={decorationImage}
                  width="109"
                  height="112"
                  alt="Authentication decoration"
                />
              </div>
            )}
          </div>
        </animated.div>
      </div>
    </div>
  )
}
