import { FC, ReactNode } from 'react'

export interface StepProps {
  title?: string
  name?: string | null
  sideImageUrl?: string
  decorationImageUrl?: string
  children?: ReactNode
}

export const Step: FC<StepProps> = ({ title, children }) => {
  return (
    <div className="px-6 py-6">
      <div className="max-w-md mx-auto">
        {title && (
          <div className="text-3xl text-slate-800 font-bold mb-6 text-lg">
            {title}
          </div>
        )}

        {children}
      </div>
    </div>
  )
}
