import {FC} from 'react'

interface LabelProps {
  text?: string | null
  required?: boolean
}

export const Label: FC<LabelProps> = ({text, required}) => {
  return (
    <label
      htmlFor={text ?? undefined}
      className="block text-gray-600 font-semibold text-sm leading-none mb-3 cursor-pointer"
    >
      {text}

      {required && <span className="text-rose-500"> *</span>}
    </label>
  )
}
