import { useParseInputProps } from '@goatlab/react-zod-form'
import { FC, ReactNode } from 'react'
import { Controller } from 'react-hook-form'
import { Label } from '../Label'
import { BaseField } from '../types/baseField'

interface RadioOption {
  label: string
  value: string | number
  leftIcon?: ReactNode
  disabled?: boolean
}

type RadioProps = BaseField<string> & {
  options: RadioOption[]
  ariaLabel?: string
}

export const Radio: FC<RadioProps> = ({
  onChange,
  options,
  formHook: hook,
  name: nameProp,
  defaultValue,
  label,
}) => {
  const { name, formHook } = useParseInputProps({
    name: nameProp,
    formHook: hook,
  })

  return (
    <>
      <Label text={label} />

      <Controller
        control={formHook?.control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange: hookChange, ...rest } }) => {
          return (
            <>
              {options.map((option, index) => {
                return (
                  <label
                    key={index}
                    className="relative block cursor-pointer"
                    onClick={() => {
                      if (option.value) {
                        onChange?.(option.value as string)
                        hookChange(option.value as string)
                      }
                    }}>
                    <input
                      type="radio"
                      {...rest}
                      defaultChecked={rest.value === option.value}
                      className="peer sr-only"
                    />
                    <div className="flex items-center bg-white text-sm font-medium text-slate-800 p-4 rounded border border-slate-200 hover:border-slate-300 shadow-sm duration-150 ease-in-out">
                      {option.leftIcon}
                      <span>{option.label}</span>
                    </div>
                    <div
                      className="absolute inset-0 border-2 border-transparent peer-checked:border-indigo-400 rounded pointer-events-none"
                      aria-hidden="true"></div>
                  </label>
                )
              })}
            </>
          )
        }}
      />

      {/* {errorMessage && (
        <div className="mt-2 text-xs text-red-600 pl-1">
          {String(errorMessage)}
        </div>
      )} */}
    </>
  )
}
