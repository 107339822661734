import { useParseInputProps } from '@goatlab/react-zod-form'
import { FC } from 'react'
import { Controller } from 'react-hook-form'
import PhoneInput from 'react-phone-number-input'
import { BaseField } from '../types/baseField'

type PhoneNumberProps = BaseField<string>

export const PhoneNumber: FC<PhoneNumberProps> = ({
  label,
  name: nameProp,
  defaultValue,
  placeholder,
  formHook: hook,
  onChange,
}) => {
  const { name, formHook, errorMessage } = useParseInputProps({
    name: nameProp,
    formHook: hook,
  })

  return (
    <div className="mb-4">
      <label
        htmlFor={name}
        className="block text-gray-600 font-semibold text-sm leading-none mb-3 cursor-pointer">
        {label}
        <span className="text-rose-500"> *</span>
      </label>

      <Controller
        control={formHook?.control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange: hookChange, ...rest } }) => {
          return (
            <PhoneInput
              id={name}
              placeholder={placeholder}
              className="w-full border border-gray-300 rounded-md px-3 py-2.5 text-gray-600 font-medium text-sm leading-none focus:outline-none focus:ring-2"
              {...rest}
              onChange={value => {
                onChange?.(value ?? '')
                hookChange(value ?? '')
              }}
              defaultCountry={'CL'}
            />
          )
        }}
      />

      {errorMessage && (
        <div className="mt-2 text-xs text-red-600 pl-1">
          {String(errorMessage)}
        </div>
      )}
    </div>
  )
}
