export const castToString = <T>(
  value: T,
): T extends unknown[] ? string[] : string => {
  if (Array.isArray(value)) {
    return value.map(String) as T extends unknown[] ? string[] : string
  }

  if (value) {
    return String(value) as T extends unknown[] ? string[] : string
  }

  return '' as T extends unknown[] ? string[] : string
}
